body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Quicksand", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #f5f6fa; */
  background: #ffffff;
}

button {
  outline: none;
  cursor: pointer;
}

input,
textarea {
  outline: none;
}

* {
  box-sizing: border-box;
}

::placeholder {
  color: #b8c5d3;
  opacity: 0.8;
}

a:link {
  text-decoration: none;
}

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}