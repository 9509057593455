@import "../AppColors";
@import "../StyleMixins";


.page-not-found-wrapper {
   @include flex(column);
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 80vh;
  padding: calc(10px + 2vw);

  h1 {
  color: $lightColor;
  font-size: calc(25px + 20vw);
  margin: 0;
  text-align: center;
}

h5 {
  margin: 0;
  text-align: center;
}

small {
  margin: 0 0 20px 0;
  text-align: center;
}
}





