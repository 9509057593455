
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #dce1eb;
  border-radius: 4px;
  background-color: #f5f6fa;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.card-errors {
font-weight: bold;
font-size: calc(5px + 0.5vw);
color: #ff0033;
width: 100%;
text-align: center;
padding-top: calc(2px + 0.2vw)
}
