@import "../../AppColors";
@import "../../StyleMixins";


.payment-details-wrapper {
    border: 1px solid $lightColor;
}

.payment-details-banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: calc(5px + 1.25vw);
}

.payment-details-header {
     @include flex(row);
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.payment-details-header-logo {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: calc(30px + 5vw);
    width: calc(100px + 5vw);
}

.payment-details-header-address {
h5{
    margin: 0;
    color: $primaryColor2;
    font-size: calc(3px + 0.75vw);
    text-align: left;
}
}


.payment-details-subheader{
    @include flex(row);
    justify-content: space-between;
    align-items: center;
    padding: calc(5px + 1.25vw);
    h5{
        margin: 0;
        font-size: calc(3px + 0.75vw);
        span{
            color: $primaryColor2;
        }
    }
}

.payment-details-body {
    width: 100%;
     padding: calc(5px + 1.25vw);
             font-size: calc(3px + 0.75vw);
             color: $greyColor;
             h3 {
                 margin: 0;
                 color: $blackColor;
                 font-size: calc(3px + 0.75vw);
             };
    .item-1{
        width: 5%;
        text-align: center;

    };

        .item-2 {
            width: 60%;
            text-align: left;
        };

                .item-3 {
                    width: 30%;
                    text-align: right;
                };
                }


.payment-details-body-header {
  @include flex(row);
  width: 100%;
  justify-content: space-between;
  align-items: center;
 
}

.payment-details-body-header {
    @include flex(row);
    width: 100%;
    justify-content: space-between;
    align-items: center;

}



.payment-details-body-details {
        @include flex(row);
        width: 100%;
        justify-content: space-between;
        align-items: center;
         border-top: 1px solid $lightColor;
         padding: calc(1px + 0.25vw) 0;
}

.payment-details-totals {
    @include flex(row);
    justify-content: flex-end;
    width: 100%;
    border-top: 1px solid $lightColor;
    padding: calc(1px + 0.25vw) calc(5px + 1.25vw);

    h2 {
        font-size: calc(4px + 1vw);
        margin: 0;

        span {
            font-size: calc(6px + 1.5vw);
            color: $primaryColor2;
        }
    }
}


@media only screen and (max-width: 700px) {
.payment-details-body {

  h3 {
    font-size: calc(4px + 1vw);
  }

  // ;

  // .item-1 {
  //   width: 5%;
  //   text-align: center;

  // }

  // ;

  // .item-2 {
  //   width: 60%;
  //   text-align: left;
  // }

  // ;

  // .item-3 {
  //   width: 30%;
  //   text-align: right;
  // }

  // ;
}

}
