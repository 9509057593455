@import "../../AppColors";
@import "../../StyleMixins";

.stripe-payment-wrapper {
  @include flex(row);
  justify-content: center;
  margin-top: calc(5px + 1.25vw);
}

.stripe-payment-form {
  background-color: $whiteColor;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  border-radius: calc(1px + 0.25vw);
  padding: 20px;
  border: 1px solid #aab7c4;
  width: 100%;
  h5 {
  margin: 0 0 5px 5px;
}

h3 {
  margin: 0 0 20px 5px;
  color: #aab7c4;
}
}


