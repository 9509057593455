@mixin flex($direction) {
    display: flex;
    flex-direction: $direction;
}

@mixin imageBackground($imageURL, $position, $size) {
    background-image: url($imageURL);
    background-position: $position;
    background-repeat: no-repeat;
    background-size: $size;
}
