@import "../AppColors";
@import "../StyleMixins";

.generate-payment-page-wrapper {
    @include flex(row);
    width: 100vw;
    height: 100vh;
    
}

.generate-payment-page-left-section {
    padding: calc(20px + 5vw);
    width: 50%;
    border-right: 1px solid $lightColor;
    h3{
       font-size: calc(4px + 1vw);
       margin: 0 0 calc(5px + 1.25vw) 0;
       span{
          font-size: calc(5px + 1.25vw);
          color: $primaryColor;
       }
    }

    h6{
      font-size: calc(3px + 0.75vw);
      color: $greyColor;
      margin: calc(5px + 1.25vw) 0 0 0;
    }
}

.generate-payment-page-input-row {
    @include flex(row);
    justify-content: space-between;
    width: 100%;
}

.generate-payment-page-input-wrapper {
  padding: 0 0 calc(2px + 0.5vw) 0;
  h5{
      margin: calc(2px + 0.5vw);
  }
}

.generate-payment-page-input-error {
    color: $primaryColor;
    margin: 0;
    font-size: calc(2px + 0.5);
}

.generate-payment-page-right-section {
    width: 50%;
    padding: calc(5px + 1.25vw);
}



.generate-payment-page-generate-button-wrapper {
     @include flex(row);
     width: 100%;
     justify-content: flex-end;
     align-items: center;
     padding-top: calc(5px + 1.25vw);
}


.generate-payment-page-payment-link-wrapper {
   @include flex(column);
   padding: calc(5px + 1.25vw);
   font-weight: bold;
   width: 100%;
   p{
     font-size: calc(3px + 0.75vw);
     margin: 0 calc(1px + 0.25vw) 0 0;
   }

   a{
       margin: 0;
       color: $primaryColor;
       font-size: calc(5px + 1.25vw);
       cursor: pointer;
       display: block;
       word-wrap: break-word;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        width: 100%;

   }

      a:hover {
          text-decoration: underline;

      }
}




