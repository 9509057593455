@import "../../AppColors";
@import "../../StyleMixins";

.subscription-date-wrapper {
  @include flex(column);
  width: 28rem;
  height: 30rem;
  position: relative;
}

.subscription-date-input-form {
  @include flex(row);
  align-items: center;
  width: 100%;

  h5 {
    color: $blackColor;
    text-align: left;
  }

  .subscription-date-input-form-details {
    flex: 1;
  }
}

.subscription-date-input {
  background: $whiteColor;
  border: 1px solid $blackColor;
  border-radius: 0.4rem;
  width: 100%;
  height: 2rem;
  text-align: center;
  font-size: 1rem;
  color: rgba($blackColor, 0.8);
}

.subscription-date-start-button {
  position: absolute;
  bottom: 0;
  width: 100%;
}
