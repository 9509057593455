@import "../AppColors";
@import "../StyleMixins";

.payments-page-wrapper {
    @include flex(row);
    justify-content: center;
    width: 100vw;
    // height: 100vh;
    position: relative;
}

.payments-page-left-section {
    width: 60%;
    height: 100%;
    overflow: scroll;
    position: relative;
        padding: calc(10px + 2.5vw) calc(10px + 2.5vw) calc(50px + 12.5vw) calc(10px + 2.5vw);
        text-align: center;
       
img {
    width: 100%;
    max-width: 600px;
}

a {
    display: block;
    background-color: $primaryColor2;
    width: fit-content;
    margin-top: calc(8px + 2vw);
    padding: calc(4px + 1vw) calc(10px + 2.5vw);
    font-size: calc(4px + 1vw);
    font-weight: bold;
    color: $whiteColor;
    border-radius: calc(1px + 0.25vw);
}
}

.payments-page-left-section-overlay {
    position: absolute;
    top:0;
    // height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2200;
    background-color: rgba(255,255,255, 0.8);
}


.payments-page-left-section-blur {
             -webkit-filter: blur(5px);
             -moz-filter: blur(5px);
             -o-filter: blur(5px);
             -ms-filter: blur(5px);
             filter: blur(5px);
}

 .payments-page-right-section-overlay {
     width: 40%;
 }

.payments-page-right-section {
    background-color: $lightColor;
    width: 100%;
    height: 100%;
    padding: calc(10px + 2.5vw);

    h6 {
        margin: calc(20px + 5vw);
        font-size: calc(8px + 2vw);
    }
}



.payments-page-tnc-row {
       @include flex(row);
       width: 100%;
       align-items: center;
padding: calc(1px + 0.25vw) calc(2px + 0.5vw);
       p {
                            margin: 0;
                            color: $blackColor;
                            font-size: calc(2px + 0.5vw);
                            padding-left: calc(1px + 0.25vw);
                                        span {
                                            color: $primaryColor;
                                            cursor: pointer;
                                            font-weight: bold;
                                        }
       }
}

.payments-page-next-payment-row {
    @include flex(row);
    width: 100%;
margin-top: calc(5px + 1.25vw);
    font-size: calc(3px + 0.75vw);
   
             p {
                 margin: 0;
                 color: $blackColor;
                 padding-right: calc(3.5px + 0.85vw);
                 line-height: calc(2px + 0.5vw);
 color: $primaryColor;
     span {
         font-size: calc(2px + 0.5vw);

     }
             }
                  span {
                      font-size: calc(2px + 0.5vw);
                       
                  }
    
}


 .payments-page-popup-button-mobile {
     display: none;
     z-index: 2500;
 }


@media only screen and (max-width: 700px) {

    .payments-page-wrapper {
        @include flex(column);
        // justify-content: center;
    }

 .payments-page-left-section {
     width: 100%;
     padding: calc(10px + 2.5vw) 0 calc(50px + 12.5vw) 0;
     flex: 1;

     a {
         margin-left: calc(8px + 2vw);
     }
 }

   .payments-page-right-section-overlay {
       background-color: rgba($blackColor, 0.5);
       width: 100%;
       position: absolute;
       top: 0;
       height: 100%;
       display: none;
       justify-content: center;
       padding: 5px;
            overflow: scroll;
            padding: 50px 0 calc(50px + 12.5vw) 0;
   }

 .payments-page-right-section {
     width: calc(250px + 25vw);
                height: min-content;
 }



 .payments-page-popup-button-mobile {
     display: inherit;
 }

  .payments-page-popup-button-mobile {
      padding: calc(10px + 2.5vw);
      background-color: $whiteColor;
  }

  .payments-page-tnc-row {
      p {
          font-size: calc(5px + 0.5vw);
      }
  }

  .payments-page-next-payment-row {
      font-size: calc(8px + 0.5vw);

      p {
          line-height: calc(5px + 0.5vw);

          span {
              font-size: calc(5px + 0.5vw);

          }
      }

      span {
          font-size: calc(5px + 0.5vw);

      }

  }

}


