@import "../../../../AppColors";
@import "../../../../StyleMixins";


.payment-successful-wrapper {
  width: calc(100px + 25vw);
  @include flex(column);
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
    font-size: calc(8px + 1.6vw);
     color: $greenColor;
  }

    p {
    text-align: center;
    font-size: calc(8px + 1.6vw);
  }
}

.payment-successful-icon {
  color: $greenColor;
  width: calc(20px + 5vw);
  height: calc(20px + 5vw);
}
